import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainComponent } from './pages/main/main.component';
import { ListadoComponent } from './pages/listado/listado.component';


//modulos del menu material
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

//modulo de iconos material
import { MatIconModule } from '@angular/material/icon';

//modulo de bootstrap 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//modulo para hacer ZOOM en una imagen
import { NgMagnizoomModule } from 'ng-magnizoom';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { CpanelComponent } from './pages/cpanel/cpanel.component';
import { TreeComponent } from './components/tree/tree.component';

//modulos del material tree
import {MatTreeModule} from '@angular/material/tree';
import { CategoriesComponent } from './components/categories/categories.component';

//modulos de la lista acordeon del material
import { MatExpansionModule } from '@angular/material/expansion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ProductListComponent } from './components/product-list/product-list.component';
import { ProductItemComponent } from './components/product-list/product-item/product-item.component';
import { AddProductComponent } from './components/product-list/add-product/add-product.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';

//modulos del dialog (modal) del material
import { MatDialogModule } from '@angular/material/dialog';

//modulos del formulario material
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//modulos del toogle material 
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

//modulos del step del material
import { MatStepperModule } from '@angular/material/stepper';

//modulo del cropper image
import { ImageCropperModule } from 'ngx-image-cropper';

//checkbox del tree, quitar cuando se termine
import { MatCheckboxModule } from '@angular/material/checkbox';

//angular notifier module
import { NotifierModule } from 'angular-notifier';
import { HttpClientModule } from '@angular/common/http';
import { LoginFormComponent } from './components/login-form/login-form.component';

//modulos de card para el login de material
import { MatCardModule } from '@angular/material/card';

//modulo del spinner de carga de datos
import { NgxSpinnerModule } from "ngx-spinner";
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

//modulo lazyload en imagenes
import { NgOptimizedImage } from '@angular/common'


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    FooterComponent,
    MainComponent,
    ListadoComponent,
    WhatsappComponent,
    ContactoComponent,
    InicioComponent,
    CpanelComponent,
    TreeComponent,
    CategoriesComponent,
    ProductListComponent,
    ProductItemComponent,
    AddProductComponent,
    ImageCropperComponent,
    LoginFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    NgbModule,
    NgMagnizoomModule,
    MatTreeModule,
    MatExpansionModule,
    DragDropModule,
    MatDialogModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    MatSlideToggleModule,
    MatStepperModule,
    ReactiveFormsModule,
    ImageCropperModule,
    MatCheckboxModule,
    NotifierModule,
    HttpClientModule,
    MatCardModule,
    NgxSpinnerModule,
    NgOptimizedImage
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
