import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, Injectable, Output} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {BehaviorSubject, Observable, of as observableOf} from 'rxjs';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
// import { MatCheckboxChange } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox'
import { NotifierService } from 'angular-notifier';
import { Menu, Seccion } from 'src/app/interfaces/interfaces';

import { v4 as uuidv4 } from 'uuid';
import { TablasService } from 'src/app/services/tablas.service';
import { environment } from 'src/environments/environment';

/**
 * File node data with nested structure.
 * Each node has a filename, and a type or a list of children.
 */
export class FileNode {
  id: string | undefined;
  children: FileNode[] | undefined;
  filename: string | undefined;
  type: any | undefined;
  idItem?: string | undefined;
}

/** Flat node with expandable and level information */
export class FileFlatNode {
  constructor(
    public expandable: boolean,
    public filename: string,
    public level: number,
    public type: any,
    public id: string,
    public categoria : String,
    public orden : Number,
    public submenu? : any,
  ) {}
}

/**
 * The file structure tree data in string. The data could be parsed into a Json object
 */

let JSON_menu: any = [
  {
    id : 'id-bañeras',
    categoria : 'Bañeras',
    orden : 1,
    submenu : [
      {
        id : 'id-ferrum',
        categoria : 'Ferrum',
        orden : 1
      },
      {
        id : 'id-roca',
        categoria : 'Roca',
        orden : 2
      },
      {
        id : 'id-pirulito',
        categoria : 'Pirulito',
        orden : 3
      }
    ]
  },
  {
    id : 'id-sanitarios',
    categoria : 'Sanitarios',
    orden : 2
  },
  {
    id : 'id-griferias',
    categoria : 'Griferias',
    orden : 3,
    submenu : []
  }
];
let TREE_DATA: string = JSON.stringify(
  {
    Applications: {
      Calendar: 'submenu',
      Chrome: 'submenu',
      Webstorm: 'submenu'
    },
    Documents: {
      angular: 'submenu',
      material2: 'submenu'
    },
    Downloads: {
      October: 'submenu',
      November: 'submenu',
      Tutorial: 'submenu'
    },
    Pictures: 'menu'
  });

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
@Injectable()

export class FileDatabase {
  dataChange = new BehaviorSubject<FileNode[]>([]);

  get data(): FileNode[] { return this.dataChange.value; }

  constructor(private tablasService: TablasService) {

    this.getMenuData();
    
  }

  getMenuData(){
    const body = {
      'accion' : 'get',
      'tabla':'cliente',     
      'select':['menu'],
      'where' :[{'campo':'usuario','tipo':'n','condicion':'=','valor': environment.email}]
    }
    
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      if(data[0].menu){
        JSON_menu = JSON.parse((data[0].menu));
        this.initialize();
      }
    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }

  convertToValidJSON(obj: any): string {
    try {
      const jsonString = JSON.stringify(obj);
      return jsonString;
    } catch (error) {
      console.error('Error al convertir el objeto a JSON:', error);
      return '';
    }
  }

  debuildMenu = (data: any) => {
    const resultado: Menu = {};
      this.convertToValidJSON(data);
      data.forEach((item:any) => {
        const { categoria, submenu } = item;
        if (submenu && submenu.length > 0) {
          resultado[categoria] = this.convertirSubMenu(submenu);
        } else {
          resultado[categoria] = 'menu';
        }
      });

    return resultado;
  }

  convertirSubMenu = (submenuOriginal: any[]) => {
    const resultado: any = {};
  
    submenuOriginal.forEach(item => {
      resultado[item.categoria] = 'submenu';
    });
  
    return resultado;
  }

  getMenuId = (categoria : string, id_aux:string) => {
      let id = 'no-id'

      let firstPositionID: number = 0;
      
      JSON_menu.forEach((item:any) => {
        
        const id_menu = '0/' + firstPositionID;
        if(item.categoria === categoria && id_menu === id_aux){
          id = item.id;
        }
        
        
        if (item.submenu && item.submenu.length > 0) {
          let secondPositionID = 0;
          item.submenu.forEach((subitem : Seccion) => {
            let id_aux2 = '0/' + firstPositionID + '/' + secondPositionID;
            if(subitem.categoria === categoria && id_aux === id_aux2){
              id = subitem.id;
            }

            secondPositionID ++;
          });
        }
        firstPositionID ++;
      });

      return  id;
  }

  initialize() {
    // Parse the string to json object.

    TREE_DATA = JSON.stringify(this.debuildMenu(JSON_menu));
    const dataObject = JSON.parse(TREE_DATA);

    // Build the tree nodes from Json object. The result is a list of `FileNode` with nested
    //     file node as children.
    const data = this.buildFileTree(dataObject, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FileNode`.
   */
  buildFileTree(obj: {[key: string]: any}, level: number, parentId: string = '0'): FileNode[] {
    return Object.keys(obj).reduce<FileNode[]>((accumulator, key, idx) => {
      const value = obj[key];
      const node = new FileNode();
      node.filename = key;
      /**
       * Make sure your node has an id so we can properly rearrange the tree during drag'n'drop.
       * By passing parentId to buildFileTree, it constructs a path of indexes which make
       * it possible find the exact sub-array that the node was grabbed from when dropped.
       */
      node.id = `${parentId}/${idx}`;
      node.idItem = this.getMenuId(key, node.id);//uuidv4();

      if (value != null) {
        if (typeof value === 'object') {
          node.children = this.buildFileTree(value, level + 1, node.id);
        } else {
          node.type = value;
        }
      }

      return accumulator.concat(node);
    }, []);
  }
}

/**
 * @title Tree with flat nodes
 */
@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [FileDatabase]
})
export class TreeComponent {
  @Output() nodeEvent = new EventEmitter<object>();
  private readonly notifier: NotifierService;

  treeControl: FlatTreeControl<FileFlatNode>;
  treeFlattener: MatTreeFlattener<FileNode, FileFlatNode>;
  dataSource: MatTreeFlatDataSource<FileNode, FileFlatNode>;
  // expansion model tracks expansion state
  expansionModel = new SelectionModel<string>(true);
  dragging = true;
  expandTimeout: any;
  expandDelay = 1000;
  validateDrop = true; //si quiero hacer que un submenu pase a ser menú
  selectedNode: any;

  nuevoItemMenu: string = '';
  nuevoItemSubMenu: string = '';

  constructor(database: FileDatabase, public dialog: MatDialog, notifierService: NotifierService, private tablasService: TablasService) {

    this.notifier = notifierService;
    this.treeFlattener = new MatTreeFlattener(this.transformer, this._getLevel,
      this._isExpandable, this._getChildren);
    this.treeControl = new FlatTreeControl<FileFlatNode>(this._getLevel, this._isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    database.dataChange.subscribe((data:any) => this.rebuildTreeForData(data));
  }

  // transformer = (node: FileNode, level: number) => {
  transformer = (node: any, level: number) => {
    return new FileFlatNode(!!node.children, node.filename, level, node.type, node.id, node.categoria, node.orden, node.submenu);
  }
  private _getLevel = (node: FileFlatNode) => node.level;
  private _isExpandable = (node: FileFlatNode) => node.expandable;

  private _getChildren = (node: FileNode): Observable<any> => observableOf(node.children);
  hasChild = (_: number, _nodeData: FileFlatNode) => _nodeData.expandable;

  // DRAG AND DROP METHODS

  shouldValidate(event: MatCheckboxChange): void {
    this.validateDrop = event.checked;
  }

  /**
   * This constructs an array of nodes that matches the DOM
   */
  visibleNodes(): FileNode[] {
    const result: any[] = [];
    
    function addExpandedChildren(node: FileNode, expanded: string[]) {
      result.push(node);
      // if (node.id && node.id && node.children && expanded.includes(node.id)) {
      if (node.id && node.id && node.children && expanded.includes(node.id)) {

        node.children.map((child:any) => addExpandedChildren(child, expanded));
      }
    }
    this.dataSource.data.forEach((node) => {
      addExpandedChildren(node, this.expansionModel.selected);
    });

    return result;
  }

  /**
   * Handle the drop - here we rearrange the data based on the drop event,
   * then rebuild the tree.
   * */
  drop(event: CdkDragDrop<string[]>) {
    // ignore drops outside of the tree
    if (!event.isPointerOverContainer) return;

    // construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state
    const visibleNodes = this.visibleNodes();

    // deep clone the data source so we can mutate it
    const changedData = JSON.parse(JSON.stringify(this.dataSource.data));

    // recursive find function to find siblings of node
    function findNodeSiblings(arr: Array<any>, id: string): any {
      let result, subResult;
      arr.forEach((item, i) => {
        if (item.id === id) {
          result = arr;
        } else if (item.children) {
          subResult = findNodeSiblings(item.children, id);
          if (subResult) result = subResult;
        }
      });
      return result;
    }
    // determine where to insert the node
    const nodeAtDest = visibleNodes[event.currentIndex]; //el nodo de destino (el nuevo ocupará su lugar)


    if (!nodeAtDest || !nodeAtDest.id) return;
    const newSiblings = findNodeSiblings(changedData, nodeAtDest.id);


    if (!newSiblings) return;
    const insertIndex = newSiblings.findIndex((s:any) => s.id === nodeAtDest.id);

    // remove the node from its old place
    const node = event.item.data;
    const siblings = findNodeSiblings(changedData, node.id);
    const siblingIndex = siblings.findIndex((n: any) => n.id === node.id);
    const nodeToInsert: FileNode = siblings.splice(siblingIndex, 1)[0];
    if (nodeAtDest.id === nodeToInsert.id) return;

    // ensure validity of drop - must be same level
    const nodeAtDestFlatNode = this.treeControl.dataNodes.find((n) => nodeAtDest.id === n.id);

    if (this.validateDrop && nodeAtDestFlatNode && nodeAtDestFlatNode.level !== node.level) {
      // alert('Items can only be moved within the same level.');
      return;
    }
    // insert node 
    newSiblings.splice(insertIndex, 0, nodeToInsert);
    
    // rebuild tree with mutated data
    this.rebuildTreeForData(changedData);

    this.saveMenu();

  }

  /**
   * Experimental - opening tree nodes as you drag over them
   */
  dragStart() {
    this.dragging = true;
  }
  dragEnd() {
    this.dragging = false;
  }
  dragHover(node: FileFlatNode) {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
      this.expandTimeout = setTimeout(() => {
        this.treeControl.expand(node);
      }, this.expandDelay);
    }
  }
  dragHoverEnd() {
    if (this.dragging) {
      clearTimeout(this.expandTimeout);
    }
  }

  /**
   * The following methods are for persisting the tree expand state
   * after being rebuilt
   */

  rebuildTreeForData(data: any) {

    for (let i = 0; i < data.length; i++) {
      data[i]['id'] = `0/${i}`; 
      if(data[i]['children']){
        for (let j = 0; j < data[i]['children'].length; j++) {
          data[i]['children'][j]['id'] = `0/${i}/${j}`; 
          data[i]['children'][j]['type'] = 'submenu';
        }
      }
    }

    this.dataSource.data = data;
    this.expansionModel.selected.forEach((id) => {
        const node = this.treeControl.dataNodes.find((n) => n.id === id);
        if(node) this.treeControl.expand(node);
      });
    }

    
  
  /**
   * Not used but you might need this to programmatically expand nodes
   * to reveal a particular node
   */
  private expandNodesById(flatNodes: FileFlatNode[], ids: string[]) {
    if (!flatNodes || flatNodes.length === 0) return;
    const idSet = new Set(ids);
    return flatNodes.forEach((node) => {
      if (idSet.has(node.id)) {
        this.treeControl.expand(node);
        let parent = this.getParentNode(node);
        while (parent) {
          this.treeControl.expand(parent);
          parent = this.getParentNode(parent);
        }
      }
    });
  }

  private getParentNode(node: FileFlatNode): FileFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }


  // codigo mio

  addNode(categoria: string, node?: any){
    const idItem = uuidv4();
    if(node){
      let orden2;
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if(this.dataSource.data[i].filename === node.filename){
          orden2 = this.dataSource.data[i].children?.length ? this.dataSource.data[i].children?.length : 0 ;
        }
        
      }
      const orden = node.id.split('')[node.id.length - 1] // ej 0/3
      const type = node.type; // puede ser 'menu' o 'submenu'
  
      const addData: FileNode = {filename: categoria, id: `0/${orden}/${orden2}`, type: 'submenu', children : undefined, idItem};
  
      const datos = this.dataSource.data;
      switch (type) {
        case 'menu':
          if(datos[orden]['children']){
            datos[orden]['children']?.push(addData);
          }else{
            datos[orden]['children'] = [addData];
          }
          break;
  
        case undefined:
          if(datos[orden]['children']){
            datos[orden]['children']?.push(addData);
          }else{
            datos[orden]['children'] = [addData];
          }
          break;
        default:
          break;
      }
      this.rebuildTreeForData(datos);
    }else{
      const addData: FileNode = {filename: categoria, id: `0/${this.dataSource.data.length}`, type: 'menu', children : undefined, idItem};
      const datos = this.dataSource.data;
      datos.push(addData);
      this.rebuildTreeForData(datos);
    }
    this.nodeEvent.emit(undefined);
    // this.notifier.notify('success', 'Nuevo item añadido con éxito');
    this.saveMenu();
    this.dialog.closeAll();
  }

  openDialog(modal:any, node?: any) {
    if(node){
      this.treeControl.expand(node);
    }
    this.selectedNode = node;
    if(modal){
      const dialogRef = this.dialog.open(modal, {
        // width: '50%'
     });
     dialogRef.afterClosed().subscribe(result => {
      this.nuevoItemSubMenu = '';
      this.nuevoItemMenu = '';
      //  console.log(`Dialog result: ${result}`);
     });
    }
  }

  deleteItem(node:any){
    let data: any = this.dataSource.data;
    for (let i = 0; i < data.length; i++) {
      
      if(node.id === data[i]['id']){
        data.splice(i, 1)
      }else{
        if(data[i] && data[i]['children']){
            for (let j = 0; j < data[i]['children'].length; j++) {
              if(node.id === data[i]['children'][j]['id']){
                data[i]['children'].splice(j, 1)
              }
            }
        }
      }
      if(data[i] && data[i]['children'] && data[i]['children'].length === 0){
        data[i]['expandable'] = false;
        delete data[i].children;
        this.nodeEvent.emit(data[i]);
      }
    }
    this.dataSource.data = data;
    // this.notifier.notify('success', 'Item eliminado con éxito');
    this.saveMenu();
    this.rebuildTreeForData(data);

  }

  ngAfterViewInit(){
    this.rebuildTreeForData(this.dataSource.data);
  }

  showInfo(node:any){
    this.selectedNode = node;
    if(this.dataSource.data && this.dataSource.data.length > 0){
      const data = this.dataSource.data;
      for (let i = 0; i < data.length; i++) {
        if(this.dataSource.data[i].id === node.id){
          this.nodeEvent.emit(this.dataSource.data[i]);
        }else{
          const children = data[i].children
          if(children && children != undefined && children.length > 0){
            for (let j = 0; j < children.length; j++) {
              if(children[j].id === node.id){
                this.nodeEvent.emit(children[j]);
              }

            }
          }
        }
        
      }
    }

  }

  editFilename(node: any){
    this.selectedNode = node;
    for (let i = 0; i < this.dataSource.data.length; i++) {
      if(this.dataSource.data[i].id === node.id
        && this.dataSource.data[i].filename !== node.filename){
        this.dataSource.data[i].filename = node.filename;
        this.saveMenu();
      }
    }
  }

  buildMenu = (data: any[]) => {
    let outputData = [];
    for (let i = 0; i < data.length; i++) {
      let aux: any = {
        id : data[i].idItem,
        categoria : data[i].filename,
        orden : i
      }
      if(data[i].children && data[i].children.length > 0){
        let aux_submenu: any[] = [];
        for (let j = 0; j < data[i].children.length; j++) {
          const aux2 = {
            id : data[i].children[j].idItem,
            categoria : data[i].children[j].filename,
            orden : j
          }
          aux_submenu.push(aux2);
        }
        aux['submenu'] = aux_submenu;
      }
      outputData.push(aux);
    }
    return outputData;
  }


  saveMenu(){
    const menuString : string = JSON.stringify(this.buildMenu(this.dataSource.data));
    this.updateDataMenu(menuString);
  }

  updateDataMenu(menu: string){
    const body = {
      'accion' : 'update',
      'tabla':'cliente',
      'update' : [{'campo':'menu','tipo':'s','valor': menu}],
      'where' :[{'campo':'usuario','tipo':'s','condicion':'=','valor': environment.email}]
    }
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      this.notifier.notify('success', 'Registros actualizados con éxito');
    })
    .catch((error) => {
      this.notifier.notify('warning', error);
       console.error('Error al obtener datos:', error);
    });
  }






}