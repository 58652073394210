import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { MailingService } from 'src/app/services/mailing.service';
import { NotifierService } from 'angular-notifier';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {

  private readonly notifier: NotifierService;
  constructor(private mailingService: MailingService, notifierService: NotifierService) {
    this.notifier = notifierService;
   }

  ngOnInit(): void {
  }

  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('',[Validators.required]),
    mail_envio: new UntypedFormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    msg: new UntypedFormControl('',[Validators.required]),
  });

  submit() {
    if(this.form.valid) {
      this.sendMail(this.form.value);
    }else{
      if(this.form.get('mail_envio')?.invalid){
        this.notifier.notify('warning', 'Su email no es válido');
      }
      if(this.form.get('name')?.invalid || this.form.get('msg')?.invalid){
        this.notifier.notify('warning', 'Debe rellenar los campos correctamente');
      }
    }
  }

  sendMail(form: any){
    const datosEnvio = {
      name : form.name,
      mail_recibo : environment.email,
      mail_envio : form.mail_envio,
      msg : form.msg
    }

    this.mailingService.sendEmail(datosEnvio).then((data) => {
      if(data.cod === 0){
        this.form.controls['name'].setValue('');
        this.form.controls['mail_envio'].setValue('');
        this.form.controls['msg'].setValue('');
        this.notifier.notify('success', 'Email enviado correctamente, se le respodnerá a la brevedad');
      }else{
        this.notifier.notify('warning', 'Error enviando correo, intente más tarde');
      }
    });
  }



}
