import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }


  getToken(body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const urlaux = `${environment.api}/login`;

      const headers = {'Content-Type':  'application/json'};

      this.http.post<any>(urlaux, body , { headers }).subscribe((data) => {
        resolve(data);
      },
      (error) => {
        reject(error);
      });
    });

  }

}
