
<div>


    <div class="row">
    
        <div class="col-lg-3 col-md-4 pl-1">
            
            <app-tree (nodeEvent)="receiveNode($event);"></app-tree>
    
        </div>
    
        <div class="col-lg-9 col-md-8 p-5 mt-2">
    
            <app-categories *ngIf="selectedNode" [selectedNodeFromFather]="selectedNode" ></app-categories>
    
        </div>
    
    </div>
    
    
    
    

</div>    


