import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit {

  whatsappLink = '';

  constructor() { }

  ngOnInit(): void {
    const mensaje = "Buenos días, me gustaría saber más sobre sus artículos";
    const mensajeCodificado = encodeURIComponent(mensaje);
    this.whatsappLink = `https://wa.me/${environment.whatsappNumber}?text=${mensajeCodificado}`;
  }

}
