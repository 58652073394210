import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {
  height: string = '0px';

  constructor() { }

  ngOnInit(): void {
    const height_aux =  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.height = height_aux + 'px;';
    // console.log(this.height);
  }

}
