import { Component, Input, TemplateRef, ViewChild } from '@angular/core';


//modulos material
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { MatDialog } from '@angular/material/dialog';
import { Producto } from 'src/app/interfaces/interfaces';
import { NotifierService } from 'angular-notifier';

import { TablasService } from 'src/app/services/tablas.service';
import { environment } from 'src/environments/environment';

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})

export class ProductListComponent {
  
  @ViewChild('productoItem') modal: TemplateRef<any> | undefined;
  @Input() newProductFromFather: any; // object | undefined;
  @Input() id_categoria: string = '';
  private readonly notifier: NotifierService;

  selectedItem: any;

  hostDir = `${environment.host}/`;
  imageError: string = '../../../assets/imagenes/imagen_no_disponible.png';
  
  constructor(public dialog: MatDialog, notifierService: NotifierService, private tablasService: TablasService,
    private spinner: NgxSpinnerService) {
    this.notifier = notifierService;
  }


  items = [{
    id: 0,
    nombre : '',
    descripcion : '',
    codigo : '',
    imagen : '',
    stock : 0,
    precio : 0,
    activo : false,
    orden : 0
  }];

  drop(event: CdkDragDrop<any>) {
    this.items[event.previousContainer.data.index] = event.container.data.item;
    this.items[event.container.data.index] = event.previousContainer.data.item;

    for (let i = 0; i < this.items.length; i++) {
      this.items[i]['orden'] = i;
      let data: any = this.items[i];
      data.orden = i;
      this.updateProducto(data); 
    }

  }

  openDialog(modal: any, item?:any) {
    if(item) this.selectedItem = item;
    if(modal){
      const dialogRef = this.dialog.open(modal, {
        maxHeight: '90vh'
        // width: '50%'
     });
     dialogRef.afterClosed().subscribe(result => {
      //  console.log(`Dialog result: ${result}`);
     });
    }
  }

  deleteItem(producto: any){
    const index = this.items.findIndex(item  => item.nombre === producto.nombre);
    this.deleteProducto(index);
  }

  ngOnChanges() {
    if(this.newProductFromFather !== undefined){
      this.insertProducto(this.newProductFromFather);
    }

    this.getProductos(this.id_categoria);
  }

  editVisibility(index: number){
    this.items[index].activo ? this.items[index].activo = false : this.items[index].activo = true;
    let data: any = this.items[index];
    data.activo = this.items[index].activo ? 1 : 0;
    this.updateProducto(data);
    this.items[index].activo = data.activo;
  }

  getProductos(id_categoria:string){
    this.spinner.show();
    const body = {
      'accion' : 'get',
      'tabla':'productos',     
      'select':['*'],
      'where' :[{'campo':'id_cliente','tipo':'n','condicion':'=','valor': ''+environment.id_cliente},
                {'campo':'id_categoria','tipo':'s','condicion':'=','valor': id_categoria}],
      "order":[{"campo":"orden","sentido":"ASC"}]
    }
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      this.items = data;
      this.spinner.hide();
    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }

  insertProducto(producto: any){
    this.spinner.show();
    const body = {
      'accion' : 'insert',
      'tabla':'productos',
      "insert":[ 
        {"campo":"id_cliente","tipo":"n","valor": ''+environment.id_cliente},
        {"campo":"imagen","tipo":"s","valor": producto.imagen},
        {"campo":"nombre","tipo":"s","valor": producto.nombre},
        {"campo":"codigo","tipo":"s","valor": producto.codigo},
        {"campo":"descripcion","tipo":"s","valor": producto.descripcion},
        {"campo":"precio","tipo":"n","valor": ''+producto.precio ? String(producto.precio) : "0"},
        {"campo":"stock","tipo":"n","valor": ''+producto.stock ? String(producto.stock): "0"},
        {"campo":"id_categoria","tipo":"n","valor": ''+this.id_categoria},
        {"campo":"activo","tipo":"n","valor": ''+1},
        {"campo":"orden","tipo":"n","valor": ''+this.items.length}
      ]
    }
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      // this.items.push(this.newProductFromFather); // poner cuando si se soluciona el tema de la nueva imagen
      this.getProductos(this.id_categoria);
      this.spinner.hide();
    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }

  deleteProducto(index: number){
    this.spinner.show();
    const body = {
      'accion' : 'delete',
      'tabla':'productos',
      'where' :[{'campo':'id','tipo':'s','condicion':'=','valor': ''+this.items[index].id}]
    }
    
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      if(data){
        this.items.splice(index, 1);
        this.notifier.notify('success', 'Producto eliminado con éxito');
      }
      this.spinner.hide();
    })
    .catch((error) => {
      console.error('Error al obtener datos:', error);
      this.notifier.notify('warning', error);
    });
  }

  updateProducto(producto: any, reload: boolean = false){
    this.spinner.show();
    let body = {
      'accion' : 'update',
      'tabla':'productos',  
      'where' :[{'campo':'id','tipo':'n','condicion':'=','valor': ''+producto.id}],
      'update' : [{'campo':'orden','tipo':'n','valor': ''+producto.orden}, //creo que tira el orden a 0 verificar
                  {'campo':'nombre','tipo':'s','valor': ''+producto.nombre},
                  {'campo':'codigo','tipo':'s','valor': ''+producto.codigo},
                  {'campo':'activo','tipo':'n','valor': producto.activo ? '1' : '0'},
                  {'campo':'descripcion','tipo':'s','valor': ''+producto.descripcion},
                  {'campo':'stock','tipo':'n','valor': ''+producto.stock},
                  {'campo':'precio','tipo':'n','valor': ''+producto.precio}]
    }

    if(producto.imagen && producto.imagen.length > 1000){
      body.update.push({'campo':'imagen','tipo':'s','valor': ''+producto.imagen});
    }


    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      this.spinner.hide();
      if(data === 1 && reload){
          this.notifier.notify('success', 'El producto ha sido actualizado con éxito');
          this.getProductos(this.id_categoria);
      }
    })
    .catch((error) => {
       console.error('Error al obtener datos:', error);
    });
  }

  receiveProductEdited(event: any){
    this.dialog.closeAll();
    this.updateProducto(event, true);
  }
  

}
