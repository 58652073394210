<notifier-container></notifier-container>

<div class="mb-5">
  <h1 style="display: inline;" *ngIf="selectedNodeFromFather.filename">{{selectedNodeFromFather.filename}}</h1>
  <button style="display: inline; float: right;" mat-flat-button (click)="openDialog(categoriaInput)">
        <mat-icon>sell</mat-icon> Nueva categoría
      </button> 
</div>

<!-- level 01 -->
<ng-template #categoriaInput>
 <div>
   <form>
     <mat-form-field>
       <mat-label>Nombre categoria</mat-label>
       <input matInput [(ngModel)]="categoria" placeholder="Ej. Vanitory" value="" [ngModelOptions]="{standalone: true}">
     </mat-form-field>
     <section>
       <div class="float-right">
         <button (click)="addCategories(categoria);" mat-flat-button>Guardar</button>
       </div>
     </section>
   </form>
 </div>
</ng-template>

<ng-template #productoInput>
  <div>
      <app-add-product (newProductEvent)="receiveNewProduct($event);" ></app-add-product>
  </div>
</ng-template>

<ng-template #deleteItemConfirmation>
  <div *ngIf="selectedCategory">
    <h1 mat-dialog-title>Eliminar '{{selectedCategory.nombre}}'</h1>
    <div mat-dialog-content>
      Realmente desea eliminar el elemento de las categorías? <br>
      <p style="color: red;">Se eliminarán todos los productos que se encuentran dentro de la categoría.</p>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button mat-dialog-close>Cancelar</button>
      <button mat-button mat-dialog-close cdkFocusInitial (click)="deleteCategories(selectedCategory);">Aceptar</button>
    </div>
  </div>
</ng-template>








<mat-accordion class="example-headers-align" cdkDropList (cdkDropListDropped)="drop($event)">
  <mat-expansion-panel (opened)="newProductToChild = undefined" (cdkDropListDropped)="drop($event)" *ngFor="let panel of categories; let i = index" cdkDrag
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header (click)="selectedCategory = panel">
        <!-- <mat-panel-title>{{ i + 1 }} </mat-panel-title> -->
      <mat-panel-description>
        <p *ngIf="!panel.editable" style="cursor: auto;" (click)="$event.stopPropagation();" (dblclick)="panel.editable = true; $event.stopPropagation();">
          {{ panel.nombre }} 
        </p>
        <mat-form-field (click)="$event.stopPropagation();" (dblclick)="$event.stopPropagation();" *ngIf="panel.editable" style="width: 80%;">
          <input (keydown.Space)="$event.stopImmediatePropagation()" matInput (focusout)="editCategory(panel, i);$event.stopPropagation();"  (keyup.enter)="editCategory(panel, i);$event.stopPropagation();" autofocus [(ngModel)]="panel.nombre">
        </mat-form-field>
        <div *ngIf="!panel.editable">
          <button (click)="openDialog(productoInput, panel); $event.stopPropagation();" mat-flat-button>
            <mat-icon>add_circle</mat-icon>
          </button>
          <button (click)="openDialog(deleteItemConfirmation, panel); $event.stopPropagation();" mat-flat-button>
            <mat-icon  class="mr-2">delete</mat-icon>
          </button>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container>

    <mat-accordion *ngIf="selectedCategory && panel.id_categoria === selectedCategory.id_categoria">

        <app-product-list [newProductFromFather]="newProductToChild" [id_categoria]="panel.id_categoria"></app-product-list>
        
    </mat-accordion>




