import { Component, EventEmitter, Output } from '@angular/core';

import { UntypedFormBuilder, Validators } from '@angular/forms';

import { Producto } from 'src/app/interfaces/interfaces';


@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent {
  @Output() newProductEvent = new EventEmitter<Object>();

  srcResult: String = '';

  firstFormGroup = this._formBuilder.group({
    nombre: ['', Validators.required],
    codigo: [''],
    descripcion: [''],
    precio: [''],
    stock: [''],
  });

  secondFormGroup = this._formBuilder.group({
    imagen: ['', Validators.required],
  });




  isLinear = false;
  imageBase64: string = '';

  constructor(private _formBuilder: UntypedFormBuilder) {}

  onFileSelected() {
    const inputNode: any = document.querySelector('#file');
  
    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.srcResult = e.target.result;
      };
  
      reader.readAsArrayBuffer(inputNode.files[0]);
    }
  }

  addProduct(){
    // nombre
    // codigo
    // descripcion
    // precio
    // stock

    const newProduct = {
      nombre : this.firstFormGroup.controls['nombre'].value,
      codigo : this.firstFormGroup.controls['codigo'].value,
      descripcion : this.firstFormGroup.controls['descripcion'].value,
      precio : this.firstFormGroup.controls['precio'].value,
      stock : this.firstFormGroup.controls['stock'].value,
      imagen : this.imageBase64
    }
    this.newProductEvent.emit(newProduct);
  }

  receiveImage($event: string){
    this.imageBase64 = $event;
  }

}
