import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TablasService {
  static PostDataFromAPI: any;
  constructor(private http: HttpClient, private router: Router) { }

  PostDataFromAPI(endpoint:string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const urlaux = `${environment.api}/${endpoint}`;
      // const authorization = 'Bearer eyAiYWxnIjogIkhTMjU2IiwgInR5cCI6ICJKV1QifQ.ewogICAgICAgICAgICAgICAgImlkX2NsaWVudGUiIDogIjc5IiwKICAgICAgICAgICAgICAgICJpYXQiOiAxNjk0OTc0ODY0LAogICAgICAgICAgICAgICAgImV4cCI6IDE3MjY1OTcyNjQKICAgICAgICAgICAgICAgIH0.7_sP08-Hu3_5Undb25eQsRSN9mSe9vVd7OaixLfUlqs';
      const authorization = this.getBearer();
      let headers;
      
      headers = {Authorization: authorization, 'Content-Type':  'application/json'};

      
      this.http.post<any>(urlaux, body , { headers }).subscribe((data) => {
        if(data.cod === 1 && data.msj === 'Invalid Token'){
          sessionStorage.removeItem('token');
          this.router.navigate(['/login']);
        }
        resolve(data.datos);
      },
      (error) => {
        reject(error);
      });
    });

  }

  getBearer = () => {
    if (typeof sessionStorage !== 'undefined') {
      // Recupera el valor almacenado en sessionStorage.
      const storedBase64 = sessionStorage.getItem('token');
      // Asigna el valor recuperado a la variable base64String.
      if(storedBase64){
        return atob(storedBase64); 
      }else{
        return '';
      }
    } else {
      console.error('El sessionStorage no está disponible en este navegador.');
      return '';
    }
  }

}
