import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {


    maxPxImage: string = 'max-width: 400px;';

    @Output() imageEvent = new EventEmitter<string>();
    constructor() { }


    ngOnInit(): void {
    }

    imageChangedEvent: any = '';
    croppedImage: any = '';
    
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        console.log(event);
        if(event.width < 400){
            this.maxPxImage = `max-width: ${event.width}px;`
        }
        this.croppedImage = event.base64;
        this.imageEvent.emit(this.croppedImage);
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

}
