import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CpanelComponent } from './pages/cpanel/cpanel.component';
import { MainComponent } from './pages/main/main.component';
import { LoginFormComponent } from './components/login-form/login-form.component';


const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'login', component: LoginFormComponent },
  { path: 'cpanel', component: CpanelComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
