<notifier-container></notifier-container>
<div  class="ml-5 mt-5 pt-3">

    <h1 class="ml-2" style="display: inline;">MENU</h1>
    <mat-icon  (click)="openDialog(menuInput); $event.stopPropagation();" style="cursor: pointer;" class="m-3">add_circle</mat-icon>
    <!-- <p><mat-checkbox (change)="shouldValidate($event)">Restrict moves to same level only</mat-checkbox></p> -->
    <mat-tree class="tree-mother" [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList (cdkDropListDropped)="drop($event)">
      <mat-tree-node class="ml-3" [ngClass]="node.type === 'submenu' ? 'tree-child' : 'tree-mother'" *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">
        <p *ngIf="!node.editable" (dblclick)="node.editable = true" (click)="showInfo(node);" class="p-0 m-0" style="cursor: grab;" [ngStyle]="{'text-decoration': selectedNode && selectedNode.filename === node.filename ? 'underline' : 'none'}" >{{node.filename}}</p>
        <mat-form-field *ngIf="node.editable">
          <input (focusout)="editFilename(node);node.editable = false;" (keyup.enter)="editFilename(node);node.editable = false;" autofocus  matInput [(ngModel)]="node.filename">
        </mat-form-field>
            <mat-icon *ngIf="node.type != 'submenu' && !node.editable" class="ml-2" (click)="openDialog(submenuInput, node);" style="display: inline; cursor: pointer;">add</mat-icon>
            <mat-icon *ngIf="!node.editable" class="ml-2" (click)="openDialog(deleteItemConfirmation, node);" style="display: inline; color: red; cursor: pointer;">delete_sweep</mat-icon>
            <!-- <mat-icon *ngIf="!node.visible" (click)="node.visible = true" class="ml-2" style="color: lightgray; cursor: pointer;">visibility_off</mat-icon>
            <mat-icon *ngIf="node.visible" (click)="node.visible = false" class="ml-2" style="color: grey; cursor: pointer;">visibility</mat-icon> -->
        </mat-tree-node>
    
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding cdkDrag [cdkDragData]="node" (mouseenter)="dragHover(node)" (mouseleave)="dragHoverEnd()" (cdkDragStarted)="dragStart()" (cdkDragReleased)="dragEnd()">
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle (click)="expansionModel.toggle(node.id)"
                  [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <!-- (click)="showInfo(node);" no lo ponemos ya que son componentes padres -->
          <p class="p-0 m-0" *ngIf="!node.editable" (dblclick)="node.editable = true" style="cursor: grab;">{{node.filename}}</p>
          <mat-form-field *ngIf="node.editable">
            <input (focusout)="editFilename(node);node.editable = false;" (keyup.enter)="editFilename(node);node.editable = false;"  matInput [(ngModel)]="node.filename">
          </mat-form-field>
          <mat-icon *ngIf="!node.editable" class="ml-2" (click)="openDialog(submenuInput, node)" style="display: inline; cursor: pointer;">add</mat-icon>
          <mat-icon *ngIf="!node.editable" class="ml-2" (click)="openDialog(deleteItemConfirmation, node);" style="display: inline; color: red; cursor: pointer;">delete_sweep</mat-icon>
          <!-- <mat-icon class="ml-2" *ngIf="!node.visible" (click)="node.visible = true" style="color: lightgray; cursor: pointer;">visibility_off</mat-icon>
          <mat-icon class="ml-2" *ngIf="node.visible" (click)="node.visible = false" style="color: grey; cursor: pointer;">visibility</mat-icon> -->
        </div>
      </mat-tree-node>
    </mat-tree>
    
</div>



<ng-template #submenuInput>
  <div>
    <form>
      <mat-form-field>
        <mat-label>Nombre item del submenu</mat-label>
        <input matInput [(ngModel)]="nuevoItemSubMenu"  placeholder="Ej. Vanitory" value="" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <section>
        <div class="float-right">
          <button (click)="addNode(nuevoItemSubMenu, selectedNode);" mat-flat-button>Guardar</button>
        </div>
      </section>
    </form>
  </div>
</ng-template>

<ng-template #menuInput>
  <div>
    <form>
      <mat-form-field>
        <mat-label>Nuevo item del menu</mat-label>
        <input matInput [(ngModel)]="nuevoItemMenu" placeholder="Ej. Vanitory" value="" [ngModelOptions]="{standalone: true}">
      </mat-form-field>
      <section>
        <div class="float-right">
          <button (click)="addNode(nuevoItemMenu);" mat-flat-button>Guardar</button>
        </div>
      </section>
    </form>
  </div>
</ng-template>

<ng-template #deleteItemConfirmation>
  <div *ngIf="selectedNode">
    <h1 mat-dialog-title>Eliminar '{{selectedNode.filename}}'</h1>
    <div mat-dialog-content>
      Realmente desea eliminar el elemento del menu?
      <p style="color: red;">Se eliminará todo lo que englobe el elemento.</p>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button mat-dialog-close>Cancelar</button>
      <button mat-button mat-dialog-close cdkFocusInitial (click)="deleteItem(selectedNode)">Aceptar</button>
    </div>
  </div>
</ng-template>




