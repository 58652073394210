import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TablasService } from 'src/app/services/tablas.service';

@Component({
  selector: 'app-cpanel',
  templateUrl: './cpanel.component.html',
  styleUrls: ['./cpanel.component.scss']
})
export class CpanelComponent implements OnInit {
  selectedNode: object | undefined;

  constructor(private tablasService: TablasService, private router: Router) {
    if(this.tablasService.getBearer() === ''){
      this.router.navigate(['/login']);
    }
   }

  ngOnInit(): void {
  }

  receiveNode(event: object){
    this.selectedNode = event;
  }

}
