import { Component, Input, OnInit } from '@angular/core';

import { Producto, Seccion } from '../../interfaces/interfaces';
import { environment } from 'src/environments/environment';
import { TablasService } from 'src/app/services/tablas.service';

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit {

  @Input() seccion: Seccion | undefined;
  hostDir = `${environment.host}/`;
  //JSON de productos ejemplo

  JSON_productos: any;

  JSON_categorias: any;

  // JSON_productos : Producto[] = [];

  titulo = '';
  imageError: string = '../../../assets/imagenes/imagen_no_disponible.png';


  constructor(private tablasService: TablasService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.titulo = this.seccion ? this.seccion?.categoria.toUpperCase() : '';
  }

  ngOnChanges() {
    this.titulo = this.seccion ? this.seccion?.categoria.toUpperCase() : '';
    this.getProductos();
  }

  getProductos(){
    this.spinner.show();
    const body = {
        'accion' : 'get',
        'tabla':'categorias',
        'tablaalias':'c',
        'select':['*'],
        'join' : [{
                'tabla' : 'productos',
                'tablaalias' :'p',
                'tipoJoin' : 'LEFT JOIN',
                'campos'   : [{'campo':'nombre','alias':'nombreproducto'},
                              {'campo':'id','alias':'id'},
                              {'campo':'imagen','alias':'imagen'},
                              {'campo':'descripcion','alias':'descripcion'},
                              {'campo':'codigo','alias':'codigo'},
                              {'campo':'stock','alias':'stock'},
                              {'campo':'precio','alias':'precio'},
                              {'campo':'activo','alias':'activo'},
                              {'campo':'orden','alias':'orden'}],
                'on':[{'campo1':'id_categoria','condicion':'=','campo2':'id_categoria'}],
                }
        ],
        'where' :[
                    {'campo':'id_menu','tipo':'s','condicion':'=','valor': this.seccion?.id},
                    {'campo':'id_cliente','tipo':'n','condicion':'=','valor': ''+environment.id_cliente}
                ],
        'order':[{'campo':'orden','sentido':'ASC'}]
    }
    this.tablasService.PostDataFromAPI('tablas', body).then((data) => {
      this.spinner.hide();
      if(data){
        this.JSON_categorias = this.groupProductsByCategory(data);
      }
    })
    .catch((error) => {
      this.spinner.hide();
      console.error('Error al obtener datos:', error);
    });
  }


  formatListado = ( data: any ) =>  {

    let listadoFormatted = [];

    return true;
  }

  // agruparProductosPorCategoria(productos: any[]): Observable<{ nombre: string, productos: any[] }[]> {
  groupProductsByCategory(productos: any[]): { categoria: string, productos: any[] }[] {
    const categorias:any = {};
    productos.sort((a,b) => a.orden - b.orden);
    productos.forEach(producto => {
      const categoria = producto.nombre;
      if (!categorias[categoria]) {
        categorias[categoria] = [];
      }
      categorias[categoria].push(producto);
    });

    const resultado = [];

    for (const categoria in categorias) {
      if (categorias.hasOwnProperty(categoria)) {
        resultado.push({ categoria, productos: categorias[categoria] });
      }
    }

    return resultado;
  }

}
