  export interface Producto {
    nombre?: String;
    codigo?: String;
    descripcion?: String;
    imagen?: String;
    id: Number;
    orden?: Number;
    precio?: Number;
    visible?: Number;
    stock?: Number;
  }

  export interface Seccion {
    id : any;
    categoria : String;
    orden : Number;
    submenu? : any;
  }

  export interface Menu {
    [key: string]: Menu | string;
  }