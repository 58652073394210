<notifier-container></notifier-container>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div #contenedor class="categories" cdkDropListGroup>
  <ng-container *ngFor="let item of items; let i = index">
    <div *ngIf="item.id > 0"
    class="categories-item"
    cdkDropList
    cdkDropListOrientation="horizontal"
    [cdkDropListData]="{ item: item, index: i }"
    (cdkDropListDropped)="drop($event)"
    style="min-height: 400px;"
    >
    <div class="inner" style="width: 400px;" (click)="openDialog(productoItem, item)" cdkDrag> <!--(click)="openDialog(productoItem)" abrir el producto para editar-> funcionalidad a futuro -->
      <!-- toogle de visibilidad -> funcionalidad a futuro -->
      <!-- <mat-slide-toggle [(ngModel)]="items[i].activo" (click)="editVisibility(i);$event.stopPropagation();" class="float-left pt-2 pl-2">Visible</mat-slide-toggle> -->

      <mat-icon (click)="openDialog(deleteItemConfirmation, item);$event.stopPropagation()" class="float-right mt-2 mr-2"  style="cursor: pointer; color: red;">delete_forever</mat-icon>
      <h1 style="cursor: pointer;" class="mt-2" >{{item.nombre}}</h1>
      <div
        style="width: 400px; height: 400px;"
        *cdkDragPlaceholder
        ></div>
            <img class="m-auto p-3" style="max-height: 300px; "  [src]="item.imagen ? hostDir + item.imagen : imageError">
            <!-- <div [innerHTML]="item.descripcion" style="white-space: pre-line;"></div> -->
        </div>
    </div>
  </ng-container>
</div>
  

<ng-template #productoItem>
  <div>
      <app-product-item [newProductToChild]="selectedItem" (editedProductEvent)="receiveProductEdited($event);"></app-product-item>
  </div>
</ng-template>

<ng-template #deleteItemConfirmation>
  <div *ngIf="selectedItem">
    <h1 mat-dialog-title>Eliminar '{{selectedItem.nombre}}'</h1>
    <div mat-dialog-content>
      Realmente desea eliminar el item?<br>
    </div>
    <div mat-dialog-actions class="float-right">
      <button mat-button mat-dialog-close>Cancelar</button>
      <button mat-button mat-dialog-close cdkFocusInitial (click)="deleteItem(selectedItem);">Aceptar</button>
    </div>
  </div>
</ng-template>