import { Component, OnInit } from '@angular/core';
import { Seccion } from 'src/app/interfaces/interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  seccion: Seccion | undefined;

  bgImages: String[] = ['1.jpg','1a.jpg','a.png','b.jpg','c.jpg','d.jpg','e.jpg','f.jpg','g.jpg','h.jpg','i.jpg','j.jpg','k.jpg'];

  bgrandom: String = '../../../assets/fondos/1.jpg';
  height: string = '600px';

  constructor() { }

  ngOnInit(): void {
    const height_aux =  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.height = height_aux + ' px;';
  }


  getSeccion(event: Seccion){
    if(event.categoria === 'Contacto' && event.id === 0){
      const contactoDiv = document.getElementById('contacto_form');
      if(contactoDiv){
        contactoDiv.scrollIntoView({
          behavior: "smooth",
          // behavior: "auto",
          block: "start",
          inline: "nearest"
        });
      }
      
    }else{
      const random = Math.floor(Math.random() * this.bgImages.length);
      this.bgrandom = `../../../assets/fondos/${this.bgImages[random]}`;
      this.seccion = event;
      window.scroll({ 
        top: 0, 
        left: 0, 
        behavior: 'smooth' 
      }); 
    }
  }

  checkWhatsApp = () => {
    if(environment.whatsappNumber && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true;
    }else{
      return false;
    }
  }

}
